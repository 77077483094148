<template>
  <section>

    <!-------------- Main content of page ---------------->

    <router-view />

    <!-- Popular products -> Only shown on Basket page -->

    <div
      v-if="
        get_popular_products.length > 0 &&
        $route.params &&
        $route.params.type === 'basket'
      "
      class="item-slider-buttons"
    >
      <h3 class="title title__normal">
        {{ translations.random_items }}
      </h3>
      <product_slider
        :products="get_popular_products"
        :load_right_away="true"
      />
    </div>

    <!-------------- Highlighted products visible on every page except for content pages ---------------->

    <highlighted_products_component 
      v-if="
        $route.name &&
        $route.name !== client_products_routes.Products &&
        $route.params.type !== 'basket' &&
        $route.params.type !== 'contact-us' &&
        highlighted_products"
    />

    <!-------------- Item sliders not visible on homepage ---------------->

    <div v-if="show_item_slider">
      <div class="item-slider-buttons">
        <h3
          v-if="viewed_products.length > 0"
          class="title title__normal"
        >
          {{ translations.recently_viewed }}
        </h3>
        <h3
          v-if="pick_similar_products.length > 0"
          class="title title__normal"
        >
          {{ translations.similar_items }}
        </h3>
      </div>
      <product_slider :products="get_recently_viewed_products_objects"/>
      <!-- <product_slider
        v-else
        :products="pick_similar_products"
      /> -->
    </div>
  </section>
</template>

<script>
import { mapActions, mapGetters, mapMutations, mapState } from "vuex"
import { slider_products_collection, highlighted_products_collection } from "../../../../data/internal_shopify_collections"
import { home, user_viewed_products } from "../../../../data/other_constants"
import { client_products_routes } from "../../../../data/routes/content-routes"
import { SHARED_STORE } from "../../../Shared/constants/other"
import { CLIENT_STORE } from "../../constants/other"
import filter_out_unavailable_products from "../../methods/filter_out_unavailable_products"
import { ARE_ANY_PRODUCTS_BEING_FETCHED, FETCH_PRODUCTS_BY_IDS, SET_VIEWED_PRODUCTS } from "../../stores/Client/constants"

const product_slider = () => import("../../../Shared/components/utils/product_slider")
const highlighted_products_component = () => import("../utils/home/highlighted_products")

export default {
  components: {
    product_slider,
    highlighted_products_component
  },
  data() {
    return {
      show_recently_viewed: true,
      internal_shopify_collections: [
        slider_products_collection,
        highlighted_products_collection
      ],
      home,
      client_products_routes,
    }
  },
  watch: {
    $route() {
      if (this.$route.name !== client_products_routes.Products) {
        this.fetch_products({ ids: this.popular_products })
        this.fetch_products({ ids: this.highlighted_products })
      }
    }
  },
  computed: {
    ...mapState(SHARED_STORE, [
      "is_mobile_device",
      "basket_items",
    ]),
    ...mapState(CLIENT_STORE, [
      "liked_products", 
      "viewed_products",
      "products",
      "translations",
      "vieving_item",
      "viewed_item_types",
      "viewed_product_id",
      "viewed_item_category",
      "shopify_instance",
      "collections",
      "popular_products",
      "highlighted_products",
      "use_shopify_data"
    ]),
    ...mapGetters(CLIENT_STORE, {
      still_fetching_products: ARE_ANY_PRODUCTS_BEING_FETCHED
    }),
    get_popular_products() {
      const found_products = this.products.filter(({ id }) => this.popular_products.includes(id))

      return [
        ...found_products,
        ...(this.still_fetching_products ? Array.from(new Array(this.popular_products.length - found_products.length)).map(_ => ({})) : [])
      ]
    },
    show_top_product_slider() {
      return this.get_popular_products.length > 0 && (
        this.$route.params.type === home || (
          this.$route.name &&
          this.$route.name !== client_products_routes.Products &&
          this.$route.params.type !== "basket" &&
          this.$route.params.type !== "order" &&
          this.$route.params.type !== "about" &&
          this.$route.params.type !== "legal-page" &&
          this.$route.params.type !== "thank-you" &&
          this.$route.params.type !== "contact-us"
        )
      )
    },
    get_recently_viewed_products_objects() {
      const found_products = this.products.filter(({ id }) => this.viewed_products.includes(id))

      return [
        ...found_products,
        ...(this.still_fetching_products ? Array.from(new Array(this.viewed_products.length - found_products.length)).map(_ => ({})) : [])
      ]
    },
    show_item_slider() {
      return this.viewed_products.length > 0 && (
        this.$route.name === client_products_routes.Products ||
        this.$route.params.type === "contact-us" ||
        this.$route.params.type === home ||
        (this.use_shopify_data && this.$route.name == "Subcategory")
      )
    },
    pick_similar_products() {
      if(this.viewed_item_category && this.viewed_item_category !== "") {
        return this.get_total_items_array(this.products, this.viewed_item_category).reduce((total ,current) => {
          if (
            current.type && 
            current.type.some(type => this.viewed_item_types.some(types => types === type)) && 
            current.id !== this.viewed_product_id
          ) return [...total, current]
            
          return total
        }, [])
      }

      return []
    },
  },
  mounted() {
    this.set_viewed()

    if (this.$route.name !== client_products_routes.Products) {
      this.fetch_products({ ids: this.popular_products })
      this.fetch_products({ ids: this.highlighted_products })
    }
  },
  methods: {
    ...mapActions(CLIENT_STORE, {
      fetch_products: FETCH_PRODUCTS_BY_IDS
    }),
    ...mapMutations(CLIENT_STORE, {
      set_viewed_products: SET_VIEWED_PRODUCTS,
    }),
    set_viewed() {
      const viewed_products = (JSON.parse(
        window.localStorage.getItem(user_viewed_products)
      ) || []).slice(0, 9)

      // Set viewed, basket and liked items from local storage
      this.set_viewed_products(viewed_products)

      this.fetch_products({ ids: viewed_products }).then(
        () => this.set_viewed_products(filter_out_unavailable_products(viewed_products)
      ))
    }
  }
}
</script>
