var render = function render(){var _vm=this,_c=_vm._self._c;return _c('section',[_c('router-view'),(
      _vm.get_popular_products.length > 0 &&
      _vm.$route.params &&
      _vm.$route.params.type === 'basket'
    )?_c('div',{staticClass:"item-slider-buttons"},[_c('h3',{staticClass:"title title__normal"},[_vm._v(" "+_vm._s(_vm.translations.random_items)+" ")]),_c('product_slider',{attrs:{"products":_vm.get_popular_products,"load_right_away":true}})],1):_vm._e(),(
      _vm.$route.name &&
      _vm.$route.name !== _vm.client_products_routes.Products &&
      _vm.$route.params.type !== 'basket' &&
      _vm.$route.params.type !== 'contact-us' &&
      _vm.highlighted_products)?_c('highlighted_products_component'):_vm._e(),(_vm.show_item_slider)?_c('div',[_c('div',{staticClass:"item-slider-buttons"},[(_vm.viewed_products.length > 0)?_c('h3',{staticClass:"title title__normal"},[_vm._v(" "+_vm._s(_vm.translations.recently_viewed)+" ")]):_vm._e(),(_vm.pick_similar_products.length > 0)?_c('h3',{staticClass:"title title__normal"},[_vm._v(" "+_vm._s(_vm.translations.similar_items)+" ")]):_vm._e()]),_c('product_slider',{attrs:{"products":_vm.get_recently_viewed_products_objects}})],1):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }